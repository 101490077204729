<template>
  <div class="hetong">
    <van-nav-bar
      :title="'kontrak'"
      @click-left="$router.go(-1)"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <table class="gridtable">
      <tr>
          <th>Nama peminjam: </th>
          <th>{{name}}</th>
      </tr>
      <tr>
          <th>Nombor kontrak: </th>
          <th>{{id}}</th>
      </tr>
      <tr>
          <th>Hari/bulan/tahun pinjaman</th>
          <th>{{getZoneTimenum}}</th>
      </tr>
      <tr>
          <th>Nombor kad pengenalan</th>
          <th>{{idCardNo}}</th>
      </tr>
      <tr>
          <th>nombor telefon</th>
          <th>{{phone}}</th>
      </tr>
      <tr>
          <th> Jumlah pinjaman (RM)</th>
          <th>{{quota}}</th>
      </tr>
      <tr>
          <th>Tempoh ansuran (bulan)</th>
          <th>{{months}}</th>
      </tr>
      <tr>
          <th>Kadar faedah bulanan</th>
          <th>{{rate}}</th>
      </tr>
      <tr>
          <th>Jadual pembayaran balik</th>
          <th>1-5 haribulan</th>
      </tr>
      <tr>
          <th>Cara meminjam</th>
          <th class="more">
            <div>Peminjam hendaklah mengisi maklumat dalam sistem dengan lengkap.Semua maklumat hendaklah diisi dengan benar. Jika pemohon pinjaman memberikan maklumat yang tidak tepat, Pemberi pinjaman boleh mengambil tindakan terhadap peminjam mengikut undang-undang. Semua maklumat yang telah disahkan oleh peminjam boleh ditukar sekali.Dan tidak akan dapat diedit lagi, hanya perlu menyerahkan pembetulan</div>
            <div>Keputusan kelulusan datang daripada jawatankuasa semakan kredit. Tidak boleh diubah Peminjam hendaklah mematuhi keputusan pertimbangan jawatankuasa dan syarat syarikat.</div>
            <div>Selepas pinjaman diluluskan Peminjam hendaklah mengikut keputusan pertimbangan jawatankuasa dalam tempoh yang ditetapkan. Selepas pemeriksaan selesai Jumlah keseluruhan kredit akan dipindahkan ke akaun yang dimasukkan ke dalam sistem oleh peminjam. Tetapi jika peminjam tidak mematuhi syarat kontrak yang diluluskan oleh kementerian kewangan kontrak ini berkesan untuk peminjam. Akan menyebabkan kontrak dibatalkan. Sekiranya kontrak dibatalkan, peminjam perlu membayar yuran pentadbiran. 50% daripada jumlah pinjaman Untuk mengeluarkan keseluruhan jumlah daripada sistem sekiranya terdapat sebarang kesilapan dalam memohon pinjaman ini, kesilapan adalah disebabkan oleh peminjam sendiri. Peminjam bertanggungjawab untuk acara ini. Dengan meletakkan deposit untuk membetulkan item deposit untuk pindaan dikira daripada jumlah pinjaman. Jika peminjam tidak bertanggungjawab atas kesilapan dalam bahagian ini yang disebabkan oleh peminjam. Syarikat boleh merampas sekuriti peminjam mengikut undang-undang. Dan akan dianggap sebagai penipuan yang disengajakan yang serius. dan akan dihukum berat mengikut undang-undang jika anda memfailkan tuntutan mahkamah terus dengan mahkamah (yuran pemprosesan dan deposit untuk semua pembetulan Peminjam akan menerima kembali wang selepas membuat pembetulan dan memindahkan baki daripada sistem.</div>
          </th>
      </tr>
      <tr>
          <th>pengenalan ciri</th>
          <th>Syarikat kami adalah syarikat swasta yang menyediakan perkhidmatan kewangan dan bekerjasama dengan bahagian pembiayaan. Dan apabila had kredit diluluskan peminjam mesti mengikut arahan. Di bahagian luar dan jika semasa pemindahan baki kepada peminjam perlu ada dana pusingan yang mencukupi untuk menerima jumlah pinjaman. Jika tidak mencukupi, pensijilan hendaklah dibuat oleh jabatan luar. Pembayaran akan menjadi hak milik peribadi peminjam. Maksudnya, pinjaman itu mempunyai kesan undang-undang. dan mesti membayar prinsipal dan faedah sewajarnya kepada syarikat Sebelum pembayaran balik, syarikat akan memberitahu peminjam 3 hari lebih awal sebelum bayaran perlu dibayar.Cara pembayaran balik adalah dengan menolak daripada akaun yang dilaporkan dalam sistem. Atau bayar melalui perkhidmatan kaunter I-perbankan mudah alih.</th>
      </tr>
      <tr>
          <th>tanggungjawab melanggar kontrak</th>
          <th>
            Semua pihak dalam kontrak mesti mematuhi perjanjian dengan tegas, dan tidak ada pihak yang akan menamatkan perjanjian melainkan jika pihak-pihak mencapai persetujuan melalui perundingan. Atau mengikut perjanjian ini, jika mana-mana pihak yang melanggar kontrak, pihak yang melanggar atau melanggar kontrak mesti menanggung kosnya. Dan kerugian yang ditanggung oleh pihak yang satu lagi akibat daripada pelanggaran itu, termasuk tanpa had Hanya yuran siasatan, yuran litigasi, kata-kata peguam. Yang mesti ditanggung oleh pihak yang melanggar jika pihak yang mungkir adalah pemberi pinjaman mesti membayar semua yuran pentadbiran kepada pihak peminjam. Dan jika peminjam ingkar peminjam mesti membayar pemberi pinjaman semua yuran pentadbiran.                                                                            (1) Dan perbelanjaan lain. Semua yang berlaku mengikut perjanjian ini.
(2) Yuran pengurusan akaun yang belum dibayar dinyatakan.
(3) Faedah terakru yang ditentukan.
(4) Yuran pengurusan akaun pinjaman.
(5) Faedah pinjaman ditentukan.
(6) Yuran pemeriksaan mel dipercepatkan.
(7) Yuran pengurusan akaun tetap.
(8) Faedah biasa.
(9) Pengetua biasa.</th>
      </tr>
      <tr>
          <th>Tandatangan peminjam</th>
          <th>
            <img width="200" :src="img" alt="">
          </th>
      </tr>
      <tr>
          <th>tandatangan syarikat pinjaman</th>
          <th>
            MAJU EXPRESS PINJAMAN BERLESEN SDN BHD
          </th>
      </tr>
      <!-- <tr>
          <th>ลายเซ็นบริษัทเงินกู้</th>
          <th>
            <img width="200" :src="require('./img/signimg.png')">
          </th>
      </tr> -->
      </table>
  </div>
</template>

<script>
import './css/my.css'
import { getAutograph, getLoanAgreementInfo } from '@/api/my'
import { loanInfo } from '../../api/wallet'
export default {
  name: 'hetong',
  data () {
    return {
      // 贷款编号
      id: '',
      // 签名
      img: '',
      // 手机号
      phone: '',
      // 贷款金额
      quota: '',
      // 利率
      rate: '',
      // 姓名
      name: '',
      // 贷款期限
      months: '',
      // 身份证
      idCardNo: '',
      // 当前时间
      getZoneTimenum: ''
    }
  },
  created () {
    // this.getSign()
    this.init_getLoanAgreementInfo()
    this.getLoanId()
  },
  methods: {
    // 获取贷款合同编号
    async getLoanId () {
      const data = await loanInfo()
      this.id = data.data.id
    },
    // 获取签名
    async getSign () {
      const { data } = await getAutograph()
      this.img = `http://${data.data}`
    },
    // 获取签名
    async init_getLoanAgreementInfo () {
      const { data } = await getLoanAgreementInfo()
      this.img = `http://${data.data.autograph}`
      this.idCardNo = data.data.idCardNo
      this.months = data.data.months
      this.name = data.data.name
      this.phone = data.data.phone
      this.quota = data.data.quota
      this.rate = data.data.rate
      this.getZoneTimenum = this.getLocalTime(8)
    },
    // 获取时间
    getLocalTime (i) {
      // 参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
      if (typeof i !== 'number') return
      var d = new Date()
      // 得到1970年一月一日到现在的秒数
      var len = d.getTime()
      // 本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000
      // 得到现在的格林尼治时间
      var utcTime = len + offset
      var time = new Date(utcTime + 3600000 * i)
      // return time.getFullYear() + '-' + time.getMonth() + 1 +'-'+dd+' '+hh+':'+mm+':'+ss
      return `${time.getFullYear()}-${time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1}-${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
    }
  }
}
</script>

<style  scoped>
table.gridtable {
  width: 100%;
  font-family: verdana,arial,sans-serif;
  font-size: 15Px;
  color:#333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
}
table.gridtable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #fff;
}
table.gridtable th:nth-of-type(1) {
  background-color: #dedede;
}
table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}
.more {
  padding: 0 !important;
}
.more div {
  border-bottom: 1Px solid #000;
  padding: 5px;
}
</style>
